import { SlideUp } from 'components/Animations';
import Navbar from 'components/Navbar';
import SubNavbar from 'components/SubNavbar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'assets/icons/edit1.svg';
import { ReactComponent as DeleteBold } from 'assets/icons/delete.svg';
import { ReactComponent as Service } from 'assets/icons/service.svg';
import useTable from 'hooks/useTable';
import Table from 'components/Table';
import EmptyState from 'components/Table/EmptyState';
import { service_list_header } from 'mocks/clinicals/services';
import { formatCurrency } from 'utils/formatCurrency';
import { ReactComponent as Dot } from 'assets/icons/veritical-dot.svg';
import { ReactComponent as Edit } from 'assets/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-icon.svg';
import MoreOptions from 'components/Options/MoreOptions';
import SingleService from './SingleService';
import Delete from 'components/Forms/Delete';
import NewService from './Components/Forms/NewService';
import NewServiceCategory from './Components/Forms/NewServiceCategory';
import { setService, useServiceState } from 'store/modules/services';
import useService from 'hooks/fetch/useService';
import useDebounce from 'hooks/useDebounce';
import { useAuthState } from 'store/modules/auth';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import FilterService from './Components/Forms/Filter';
import Restricted from 'HOC/Restricted';
import usePermission from 'hooks/usePermission';

const ViewServiceCategory = () => {
  const { hasPermission } = usePermission()
  const [formType, setFormType] = useState('');
  const [type, setType] = useState('');
  const { get_services, delete_service_category, loading, delete_service } = useService();
  const { params, changePage, selectLimit, handleSearch, openFilterModal, closeFilterModal, filterModal } = useTable();
  const { id } = useParams();
  const { user, institutionId, userType } = useAuthState();
  const { services, selectedService, selectedCategory } = useServiceState();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { debouncedValue } = useDebounce(params.search);
  const service = searchParams.get('service');

  const getServices = () => {
    const userAccount = ['institution', 'staff'].includes(userType) ? 'institution' : 'provider'
    get_services({
      ...params,
      category: id,
      type: userAccount,
      providerId: userAccount === 'institution' ? '' : user?._id,
      institutionId,
    });
  };

  const handleSingleView = (item) => {
    navigate(`?service=${item.name}`);
    dispatch(setService(item));
  };

  const deleteItem = () => {
    if (type === 'Service') {
      delete_service(selectedService?._id, () => {
        getServices();
        setFormType('');
        setSearchParams('');
      });
    } else {
      delete_service_category(id, () => {
        setFormType('');
        navigate('/service/category');
      });
    }
  };

  const handleModalSwitch = (id, name) => {
    setType(id);
    setFormType(name);
  };

  const NewFormType = type === 'Category' ? NewServiceCategory : NewService;
  const options = [
    {
      id: 1,
      name: 'Edit service',
      value: 'edit',
      func: () => {
        handleModalSwitch('Service', 'Edit');
      },
      icon: <Edit className="shrink-0 cursor-pointer mr-2 h-[12.5px] w-[12.5px]" fill="#505959" />,
      permission: hasPermission('Service Management', 'Update services')
    },
    {
      id: 2,
      name: 'Delete service',
      func: () => handleModalSwitch('Service', 'Delete'),
      value: 'delete',
      icon: <DeleteIcon className="w-[12px] h-[13px] mr-2" />,
      permission: hasPermission('Service Management', 'Delete')
    },
  ];

  useEffect(() => {
    getServices();
  }, [debouncedValue, params.page, params.limit]);

  const showDropdown = hasPermission('Service Management', 'Update services') || hasPermission('Service Management', 'Delete service')

  return (
    <>
      <Navbar title="Service Management" />
      <Restricted module='Service Management' permission='View service'>
        <>
          <SlideUp>
            <SubNavbar
              previous="Service Management"
              prePrev={service ? `${id}` : ''}
              current={service ? `${service}` : `${id}`}
              onClick={() => navigate('/service/category')}
              prevOnClick={() => navigate(`/service/category/${id}`)}
            />
            {!service ? (
              <div className="p-6">
                <div className="bg-brand_primary p-4 rounded-2xl">
                  <div className="flex justify-between">
                    <div className="flex space-x-3 w-3/4">
                      <div className="bg-[#EFF7FB1F] rounded-full h-10 w-10 flex justify-center items-center">
                        <Service fill="#ffff" />
                      </div>
                      <div className="text-neutral_white ">
                        <p className="font-campton_m">{id}</p>
                        <p className="font-campton_r text-12 mt-1">{selectedCategory?.description}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      {hasPermission('Service Management', 'Update category') &&
                        <EditIcon
                          className="w-10 h-10 cursor-pointer"
                          data-testid="test-edit-category"
                          onClick={() => handleModalSwitch('Category', 'Edit')}
                        />
                      }
                      {hasPermission('Service Management', 'Delete category') &&
                        <DeleteBold
                          className="w-10 h-10 cursor-pointer"
                          data-testid="test-delete-category"
                          onClick={() => handleModalSwitch('Category', 'Delete')}
                        />
                      }
                    </div>
                  </div>
                  <div className="border-t border-[#ECEEEE1F] pt-4 mt-4 grid lg:grid-cols-4 grid-cols-1 gap-6">
                    <div className="border-r border-[#ECEEEE]">
                      <p className="text-[#C7E5EF] text-12 font-campton_r">Date Created</p>
                      <p className="text-neutral_white font-campton_r text-14">
                        {dayjs(selectedCategory?.createdAt).format('MMM DD, YYYY, hh:mm A')}
                      </p>
                    </div>
                    <div className="">
                      <p className="text-[#C7E5EF] text-12 font-campton_r">Total Services</p>
                      <p className="text-neutral_white font-campton_r text-14">{services?.total || 0}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-6 h-full">
                  <Table
                    title={`${id} List`}
                    headers={service_list_header(id)}
                    data={services?.services}
                    totalCount={services?.total}
                    filterFnc={openFilterModal}
                    perPage={services?.limit}
                    currentPage={services?.page}
                    selectLimit={selectLimit}
                    changePage={changePage}
                    searchPlaceholder={`Search by ${id?.toLowerCase()} name`}
                    loading={loading}
                    handleSearch={handleSearch}
                    emptyState={<EmptyState title="No Services Added" className="h-[calc(100vh-530px)] bg-neutral_white" />}
                    buttonName="New Service"
                    buttonFnc={() => handleModalSwitch('Service', 'New')}
                    module="Service Management"
                    permission="Create service"
                  >
                    <>
                      {services?.services?.map((item) => (
                        <tr
                          data-testid={item?._id}
                          key={item?._id}
                          className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 "
                        >
                          <td
                            className="pr-[10px] py-[11px] flex items-center w-[260px] cursor-pointer"
                            onClick={() => handleSingleView(item)}
                          >
                            <p title={item?.name} className="truncate capitalize-first">
                              {item?.name}
                            </p>
                          </td>
                          <td className="px-[10px] py-[11px] capitalize">{item?.subCategory}</td>
                          <td className="px-[10px] py-[11px] capitalize">
                            {formatCurrency(item?.serviceProperties[0]?.totalFee)}
                          </td>
                          <td className="px-[10px] py-[11px] capitalize">
                            {dayjs(item?.createdAt).format('MMM DD, YYYY')}
                          </td>
                          <td className="px-[10px] py-[11px]">{dayjs(item?.updatedAt).format('MMM DD, YYYY')}</td>
                          <td className="px-[10px] py-[11px]">
                            {showDropdown &&
                              <MoreOptions position="right-[-10px] w-[170px]" icon={Dot}>
                                <>
                                  {options?.filter(o => o.permission)?.map((option) => (
                                    <button
                                      key={option?.id}
                                      type='button'
                                      data-testid={option?.value}
                                      className="w-full
                                      cursor-pointer flex items-center first-of-type:mt-0 mt-2 py-[9.5px] hover:bg-[#dfe2e23b]
                                     text-neutral_body hover:text-neutral_black hover:pl-[8px] rounded-[50px]"
                                      onClick={() => {
                                        option.func();
                                        dispatch(setService(item));
                                      }}
                                    >
                                      <p className="flex items-center">
                                        {option.icon}
                                        <span className="inline-block">{option?.name}</span>
                                      </p>
                                    </button>
                                  ))}
                                </>
                              </MoreOptions>
                            }
                          </td>
                        </tr>
                      ))}
                    </>
                  </Table>
                </div>
              </div>
            ) : (
              <SingleService
                editFunc={() => handleModalSwitch('Service', 'Edit')}
                delFunc={() => handleModalSwitch('Service', 'Delete')}
              />
            )}
          </SlideUp>
          {formType === 'Delete' &&
            <Delete
              title={`Delete Service ${type === 'Category' ? type : ''}`}
              description={`Would you like to delete this service ${type === 'Category' ? type.toLowerCase() : ''}?`}
              cancel={() => setFormType('')}
              loading={loading}
              deleteFnc={deleteItem}
            />
          }
          {formType && formType !== 'Delete' &&
            <NewFormType
              closeModal={() => setFormType('')}
              type={formType}
              title={`${formType} Service ${type === 'Category' ? type : ''}`}
              data={type === 'Service' ? selectedService : selectedCategory}
              callback={getServices}
            />
          }
          {filterModal && <FilterService closeModal={closeFilterModal} />}
        </>
      </Restricted>
    </>
  );
};

export default ViewServiceCategory;
