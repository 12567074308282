import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Export } from 'assets/icons/export.svg';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { Button } from 'components/Buttons';
import { Search } from 'components/Inputs';
import Restricted from 'HOC/Restricted';

export default function TableFilter({
  buttonName,
  title,
  handleSearch,
  buttonFnc,
  filterFnc,
  filters,
  searchPlaceholder,
  exportFunc,
  clearFilters,
  module,
  permission
}) {
  return (
    <>
      <div className="flex items-center justify-between mb-[16px]">
        <div className="sm:flex items-center w-full">
          <div className="flex justify-between items-center mb-[16px] sm:mb-0">
            <p className="text-16 text-neutral_black font-campton_m mr-[16px] capitalize">{title}</p>
            <Restricted module={module} permission={permission}>
              <>
                {buttonName ? (
                  <Button
                    name={buttonName}
                    theme="blue"
                    className="h-[40px] text-16 block sm:hidden px-[16px]"
                    onClick={buttonFnc}
                  />
                ) : (
                  ''
                )}
              </>
            </Restricted>
          </div>
          <div className="flex justify-between items-center flex-1">
            <Search id="search" name="search" placeholder={searchPlaceholder || 'Search'} onChange={handleSearch} />
            <div className="flex items-center ml-[8px]">
              <Restricted module={module} permission={'Export/Print'}>
                <Export
                  data-testid="export"
                  className="mr-[8px] shrink-0 w-[40px] cursor-pointer"
                  onClick={exportFunc}
                />
              </Restricted>
              {filterFnc && <Filter data-testid="filter" className="shrink-0 w-[40px] cursor-pointer" onClick={filterFnc} />}
              <Restricted module={module} permission={permission}>
                <>
                  {buttonName ? (
                    <Button
                      name={buttonName}
                      theme="blue"
                      width="w-[116px]"
                      className="h-[40px] hidden sm:block ml-[8px] px-[16px] text-16"
                      onClick={buttonFnc}
                    />
                  ) : (
                    ''
                  )}
                </>
              </Restricted>
            </div>
          </div>
        </div>
      </div>
      {filters?.length ? (
        <div className="flex text-14 mb-[16px]">
          {filters?.map(([key, value]) => (
            <p key={key} className="text-neutral_black pr-[8px] mr-[8px] border-r border-r-neutral_stroke_2">
              {key}-<span className="text-neutral_body">{value}</span>
            </p>
          ))}
          <button data-testid="clearFilter" className="text-brand_secondary underline px-[8px]" onClick={clearFilters}>
            clear filters
          </button>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

TableFilter.propTypes = {
  buttonName: PropTypes.string,
  title: PropTypes.string,
  handleSearch: PropTypes.func,
  buttonFnc: PropTypes.func,
  filterFnc: PropTypes.func,
  filters: PropTypes.array,
  searchPlaceholder: PropTypes.string,
  exportFunc: PropTypes.func,
  clearFilters: PropTypes.func,
  module: PropTypes.string,
  permission: PropTypes.string
};
