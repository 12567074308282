import React from "react"
import PropTypes from "prop-types"
import Button from "components/Buttons/Button"
import Modal from "components/Modals/Modal"

export default function Delete({ loading, title, cancel, deleteFnc, disabled, description, buttonName, icon }) {
    return (
        <div className="z-[500] absolute top-[50%] left-[50%]">
            <Modal
                closeModal={cancel}
                styles="w-[365px] max-w-[365px]"
            >
                <div className="flex flex-col items-center text-center">
                    {icon || <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="48" height="48" rx="24" fill="#FDF2F2" />
                        <path d="M35.5 14H30.5V13C30.5 12.2044 30.1839 11.4413 29.6213 10.8787C29.0587 10.3161 28.2956 10 27.5 10H21.5C20.7044 10 19.9413 10.3161 19.3787 10.8787C18.8161 11.4413 18.5 12.2044 18.5 13V14H13.5C13.2348 14 12.9804 14.1054 12.7929 14.2929C12.6054 14.4804 12.5 14.7348 12.5 15C12.5 15.2652 12.6054 15.5196 12.7929 15.7071C12.9804 15.8946 13.2348 16 13.5 16H14.5V34C14.5 34.5304 14.7107 35.0391 15.0858 35.4142C15.4609 35.7893 15.9696 36 16.5 36H32.5C33.0304 36 33.5391 35.7893 33.9142 35.4142C34.2893 35.0391 34.5 34.5304 34.5 34V16H35.5C35.7652 16 36.0196 15.8946 36.2071 15.7071C36.3946 15.5196 36.5 15.2652 36.5 15C36.5 14.7348 36.3946 14.4804 36.2071 14.2929C36.0196 14.1054 35.7652 14 35.5 14ZM20.5 13C20.5 12.7348 20.6054 12.4804 20.7929 12.2929C20.9804 12.1054 21.2348 12 21.5 12H27.5C27.7652 12 28.0196 12.1054 28.2071 12.2929C28.3946 12.4804 28.5 12.7348 28.5 13V14H20.5V13ZM32.5 34H16.5V16H32.5V34ZM22.5 21V29C22.5 29.2652 22.3946 29.5196 22.2071 29.7071C22.0196 29.8946 21.7652 30 21.5 30C21.2348 30 20.9804 29.8946 20.7929 29.7071C20.6054 29.5196 20.5 29.2652 20.5 29V21C20.5 20.7348 20.6054 20.4804 20.7929 20.2929C20.9804 20.1054 21.2348 20 21.5 20C21.7652 20 22.0196 20.1054 22.2071 20.2929C22.3946 20.4804 22.5 20.7348 22.5 21ZM28.5 21V29C28.5 29.2652 28.3946 29.5196 28.2071 29.7071C28.0196 29.8946 27.7652 30 27.5 30C27.2348 30 26.9804 29.8946 26.7929 29.7071C26.6054 29.5196 26.5 29.2652 26.5 29V21C26.5 20.7348 26.6054 20.4804 26.7929 20.2929C26.9804 20.1054 27.2348 20 27.5 20C27.7652 20 28.0196 20.1054 28.2071 20.2929C28.3946 20.4804 28.5 20.7348 28.5 21Z" fill="#DF3939" />
                    </svg>}
                    <h3 className="text-16 text-neutral_black font-campton_m mb-[8px] mt-[24px]">{title}</h3>
                    <p className="text-14 text-neutral_body leading-[21px] mb-[24px] font-campton_r">{description || 'Lorem ipsum dolor sit amet consectetur. Nam pharetra tortor non felis.'}</p>
                    <div className="flex w-full pt-4 border-t border-neutral_stroke_2">
                        <Button
                            name={buttonName || 'Delete'}
                            theme="transparent"
                            className="w-full h-10 mr-[16px] capitalize"
                            disabled={disabled || loading}
                            loading={loading}
                            onClick={deleteFnc}
                        />
                        <Button
                            name="Cancel"
                            theme="blue"
                            className="w-full h-10"
                            disabled={loading}
                            onClick={cancel}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

Delete.propTypes = {
    title: PropTypes.string,
    cancel: PropTypes.func,
    buttonName: PropTypes.string,
    deleteFnc: PropTypes.func,
    icon: PropTypes.any,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    description: PropTypes.string
}