import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "components/Buttons"
import { Input, TextArea } from "components/Inputs"
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-icon.svg';
import { AnimatePresence, motion } from "framer-motion"
import useService from "hooks/fetch/useService";
import { useSelector } from "react-redux";

export default function NewRequest({ callback, next, selectedTest, setSelectedTest, note, setNote }) {
    const { institutionId, userType } = useSelector(state => state.auth)
    const { institutionServices } = useSelector(state => state.services)
    const [testType, setTestType] = useState('')
    const { get_institution_services } = useService()

    const isSelected = (e) => {
        const newArray = selectedTest?.find(item => item === e)
        return newArray
    }

    const selectTest = (e) => {
        if (!isSelected(e)) return setSelectedTest([...selectedTest, { testType: e }])
    }

    const deleteTest = (e) => {
        const newArray = selectedTest?.filter(item => item.testType !== e)
        setSelectedTest(newArray)
    }

    const handleKeyDown = (e) => {
        if (e.key !== 'Enter') return
        setSelectedTest([...selectedTest, { testType: e.target.value }])
        setTestType('')
    };

    const suggestions = useMemo(() => {
        if (testType?.length < 2) return
        const regex = new RegExp(testType, 'i')
        const services = institutionServices?.map(t => t?.subCategory)
        return services?.filter((t) => regex.test(t))
    }, [testType, institutionServices])

    useEffect(() => {
        const params = { institutionId, type: userType, category: "Lab Test Management" }
        get_institution_services(params)
    }, [])

    return (
        <div className="flex flex-col justify-between h-full relative">
            <div className="h-[80vh] pt-[8px] pb-[32px] overflow-auto">
                <div className="mb-[24px]">
                    <Input
                        label="Test type"
                        type="text"
                        id="testType"
                        name="testType"
                        value={testType}
                        onChange={(e) => setTestType(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <div className="flex flex-wrap">
                        {suggestions?.map(suggestion =>
                            <button
                                key={suggestion}
                                type="button"
                                data-testid={suggestion}
                                className="text-12 text-neutral_black font-campton_r
                                    leading-[18px] bg-[#F2F3F3] py-[4px] px-[8px] mr-[8px] w-fit mb-[8px] cursor-pointer"
                                onClick={() => selectTest(suggestion)}
                            >
                                {suggestion}
                            </button>
                        )}
                    </div>
                </div>
                {selectedTest?.length ?
                    <div className="mb-[24px]">
                        <p className="text-12 text-neutral_body font-campton_r mb-[8px]">Added test type</p>
                        <ul className="bg-[#F2F3F3] rounded-[8px] px-[16px]">
                            <AnimatePresence>
                                {selectedTest?.map(suggestion =>
                                    <motion.li
                                        key={suggestion?.testType}
                                        initial={{ opacity: 0, y: "10px" }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, scale: 0 }}
                                        className="text-12 text-neutral_black font-campton_r
                                    border-b border-dashed border-b-neutral_stroke_1 w-full last:border-0
                                    leading-[18px] bg-[#F2F3F3] py-[16px] mr-[8px] flex justify-between items-center"
                                    >
                                        <p title={suggestion?.testType} className="w-[90%] capitalize-first truncate">{suggestion?.testType}</p>
                                        <DeleteIcon
                                            className="w-[12px] h-[13px] mr-2 cursor-pointer"
                                            onClick={() => deleteTest(suggestion?.testType)}
                                        />
                                    </motion.li>
                                )}
                            </AnimatePresence>
                        </ul>
                    </div> : ''
                }
                <div className="mb-[26.5px]">
                    <TextArea
                        label="Add note"
                        id="note"
                        name="note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        rows={6}
                    />
                </div>
            </div>
            <div className="flex justify-end absolute bottom-0 left-0 pt-[16px] bg-neutral_white w-full">
                <Button
                    name="Cancel"
                    theme="transparent"
                    className="h-[40px] text-16 px-[12px] w-[78px] mr-[17px]"
                    onClick={() => callback()}
                />
                <Button
                    name="Next"
                    theme="blue"
                    className='h-[40px] text-16 px-[12px] w-[104px]'
                    onClick={() => next()}
                    disabled={!selectedTest?.length}
                />
            </div>
        </div >
    )
}

NewRequest.propTypes = {
    callback: PropTypes.func,
    next: PropTypes.func,
    setSelectedTest: PropTypes.func,
    selectedTest: PropTypes.array,
    note: PropTypes.string,
    setNote: PropTypes.func
}