import React, { useMemo, useState } from 'react';
import { ReactComponent as CloseDrawer } from 'assets/icons/close-drawer.svg';
import { ReactComponent as OpenDrawer } from 'assets/icons/open-drawer.svg';
import { ReactComponent as Logo } from 'assets/icons/logo-icon.svg';
import { ReactComponent as LogoName } from 'assets/icons/logo-name.svg';
//import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as Appointment } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { logout } from 'store/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from 'store/modules/global';
import List from './List';
import { useLocation, useNavigate } from 'react-router-dom';
import ClinicalOption from './SubMenuOptions/ClinicalOptions';
import SettingsOption from './SubMenuOptions/SettingOptions';
import EShift from './SubMenuOptions/EShift';
import ServiceOption from './SubMenuOptions/ServiceOption';
import { ReactComponent as Service } from 'assets/icons/service.svg';
import usePermission from 'hooks/usePermission';
import useWindowWidth from 'hooks/useWindowWidth';
import { MEDIUM_WIDTH } from 'constants';
import './Drawer.css'

export default function Drawer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //const { user } = useSelector(state => state.auth)
  const [currentPage, setCurrentPage] = useState(pathname);
  const [toggleEShift, setToggleEShift] = useState(false);
  const [toggleClinicals, setToggleClinicals] = useState(false);
  const [toggleService, setToggleService] = useState(false)
  const [toggleSettings, setToggleSettings] = useState(false);
  const { showDrawer } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const { hasModulePermission } = usePermission()
  const windowWidth = useWindowWidth()

  const iconMargin = useMemo(() => {
    return showDrawer ? 'mr-[13px] shrnk-0' : 'shrink-0';
  }, [showDrawer]);

  const hasClinicalsPermission =
    hasModulePermission('Patient Management') || hasModulePermission('Inpatient Management') ||
    hasModulePermission('Vital Signs') || hasModulePermission('Visits') ||
    hasModulePermission('Lab Test') || hasModulePermission('Medications')

  const containerStyle = useMemo(() => {
    if (windowWidth > MEDIUM_WIDTH) return "md:block relative rounded-[16px] mr-[24px] h-full"
    return "fixed z-40 left-0 top-0 h-[100dvh]"
  }, [windowWidth])

  const width = useMemo(() => {
    if (windowWidth > MEDIUM_WIDTH) return showDrawer ? 'increase-width' : 'decrease-width'
    return showDrawer ? 'show-drawer' : 'hide-drawer'
  }, [windowWidth, showDrawer])

  return (
    <aside className={`${containerStyle} shrink-0 bg-accent_blue px-[16px] py-[22px] ${width}`}>
      <div
        data-testid="drawer-control"
        onClick={() => dispatch(toggleDrawer())}
        className="absolute right-[-20px] cursor-pointer hide_tap"
      >
        {showDrawer ? <CloseDrawer /> : <OpenDrawer />}
      </div>
      <div className={`flex ${showDrawer ? '' : 'justify-center'} items-center pb-[21px] border-b border-b-[#ffffff20]`}>
        <Logo className={`${showDrawer ? 'mr-[6.42px]' : ''} w-[29px] h-[28px]`} />
        <LogoName className={showDrawer ? 'block' : 'hidden'} />
      </div>
      <div className="flex flex-col justify-between h-[96%] overflow-scroll no-scrollbar">
        <div className="py-[16px] mt-4">
          {/* <List
              name="Dashboard"
              showDrawer={showDrawer}
              page={currentPage}
              onClick={() => {
                setCurrentPage('dashboard');
                navigate('/dashboard');
              }}
              icon={<Dashboard className={iconMargin} />}
            /> */}
          {hasClinicalsPermission ?
            <div>
              <List
                name="Clinicals"
                showDrawer={showDrawer}
                page={currentPage}
                more
                toggle={toggleClinicals}
                onClick={() => {
                  setCurrentPage('clinicals');
                  setToggleClinicals(!toggleClinicals);
                }}
                icon={<Plus fill='white' className={iconMargin} />}
              />
              <ClinicalOption showDrawer={showDrawer} iconMargin={iconMargin} toggleClinicals={toggleClinicals} />
            </div> : ''
          }
          {/* {isAnInstitution ?
            <div>
              <List
                name="e-Shift"
                showDrawer={showDrawer}
                page={currentPage}
                more
                toggle={toggleEShift}
                onClick={() => {
                  setCurrentPage('e-shift');
                  setToggleEShift(!toggleEShift);
                }}
                icon={<Clock fill='white' className={`${iconMargin} w-[18px]`} />}
              />
              <EShift showDrawer={showDrawer} iconMargin={iconMargin} toggleEShift={toggleEShift} />
            </div> :
            <>
              {hasModulePermission('Scheduling') ?
                <List
                  name="e-Shift Management"
                  showDrawer={showDrawer}
                  page={currentPage}
                  onClick={() => {
                    setCurrentPage('e-Shift Management');
                    navigate('/e-shift-management');
                  }}
                  icon={<Clock fill='white' className={`${iconMargin} w-[18px]`} />}
                /> : null
              }
            </>
          } */}
          <div>
            <List
              name="e-Shift"
              showDrawer={showDrawer}
              page={currentPage}
              more
              toggle={toggleEShift}
              onClick={() => {
                setCurrentPage('e-shift');
                setToggleEShift(!toggleEShift);
              }}
              icon={<Clock fill='white' className={`${iconMargin} w-[18px]`} />}
            />
            <EShift showDrawer={showDrawer} iconMargin={iconMargin} toggleEShift={toggleEShift} />
          </div>
          <List
            name="Appointments"
            showDrawer={showDrawer}
            page={currentPage}
            onClick={() => {
              setCurrentPage('appointments');
              navigate('/appointments');
            }}
            icon={<Appointment fill='white' className={iconMargin} />}
          />
          <div>
            <List
              name="Service Mgt."
              showDrawer={showDrawer}
              page={currentPage}
              more
              toggle={toggleService}
              onClick={() => {
                setCurrentPage('service-mgt');
                setToggleService(!toggleService);
              }}
              icon={<Service fill='white' className={iconMargin} />}
            />
            <ServiceOption showDrawer={showDrawer} iconMargin={iconMargin} toggleService={toggleService} />
          </div>
        </div>
        <div>
          <List
            name="Organisation Mgt."
            showDrawer={showDrawer}
            page={currentPage}
            more
            toggle={toggleSettings}
            onClick={() => {
              setCurrentPage('Organisation Mgt.');
              setToggleSettings(!toggleSettings);
            }}
            icon={<Settings className={iconMargin} />}
          />
          <SettingsOption showDrawer={showDrawer} iconMargin={iconMargin} toggleSettings={toggleSettings} />
          <List
            name="Logout"
            page={currentPage}
            showDrawer={showDrawer}
            onClick={() => dispatch(logout())}
            icon={<Logout fill='white' className={iconMargin} />}
          />
        </div>
      </div>
    </aside>
  );
}
