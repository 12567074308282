import React, { useContext, useState } from 'react';
import { ReactComponent as MedicalHistoryIcon } from 'assets/svg/medicalHistory.svg';
import { ReactComponent as Theraphy } from 'assets/svg/therapies.svg';
import { ReactComponent as Procedure } from 'assets/svg/procedure.svg';
import { ReactComponent as ImmunizationIcon } from 'assets/svg/immunization.svg';
import { ReactComponent as BloodDrop } from 'assets/svg/blood-type.svg';
import { ReactComponent as SurgeriesIcon } from 'assets/svg/surgeries.svg';
import { ReactComponent as AllergiesIcon } from 'assets/svg/allergies.svg';
import { ReactComponent as FamilyHistoryIcon } from 'assets/svg/family-history.svg';
import { ReactComponent as Lifestyle } from 'assets/svg/lifestyle.svg';
import { ReactComponent as Insurance } from 'assets/svg/insurance.svg';
import Modal from 'components/Modals/Modal';
import {
    AddAllergies,
    AddBloodType,
    AddFamilyHistory,
    AddFamilyHospital,
    AddImmunization,
    AddMedicalHistory,
    AddEmergencyContact,
    AddSurgeries,
    AddInsuranceInfo
} from './Forms';
import { PatientContext } from 'context';
import { SlideUp } from 'components/Animations';
import {
    BloodType,
    FamilyHospital,
    MedicalHistory,
    Surgeries,
    Allergies,
    Immunization,
    FamilyHistory,
    LifestyleHabits,
    EmergencyContact,
    InsuranceInformation
} from 'components/MedicalRecords/Tabs';
import PropTypes from 'prop-types';
import useLifestyleLogic from 'components/MedicalRecords/useLifestyleLogic';
import StatusCard from 'components/StatusCard';

export default function PatientHistory({ className, loading }) {
    const [formType, setFormType] = useState();
    const [view, setView] = useState('');
    const { patient, visitStatus, canUpdate } = useContext(PatientContext)
    const { totalAnswers } = useLifestyleLogic()

    const forms = {
        'Blood Type': AddBloodType,
        'Emergency Contact': AddEmergencyContact,
        'Family Hospital': AddFamilyHospital,
        'Family History': AddFamilyHistory,
        Allergies: AddAllergies,
        Surgeries: AddSurgeries,
        'Insurance Information': AddInsuranceInfo,
        'Immunization History': AddImmunization,
        'Medical History': AddMedicalHistory,
    };

    const views = {
        'Blood Type': BloodType,
        'Family History': FamilyHistory,
        'Family Hospital': FamilyHospital,
        'Emergency Contact': EmergencyContact,
        'Medical History': MedicalHistory,
        Surgeries: Surgeries,
        'Insurance Information': InsuranceInformation,
        'Lifestyle Habit': LifestyleHabits,
        Allergies: Allergies,
        'Immunization History': Immunization,
    };

    const View = views[view];
    const Form = forms[formType];

    const handleCallback = () => {
        setFormType('');
    };

    return (
        <div className={className}>
            {!view && <h2 className="text-16 sm:text-18 text-[#0D0F11] font-campton_m">Patient History</h2>}
            <SlideUp>
                {view === '' && (
                    <div className="mt-4 grid grid-cols-12">
                        <StatusCard
                            containerVariant="col-span-6 pb-4 lg:col-span-4 border-r border-neutral_stroke_2 border-b pr-4"
                            variant="px-4"
                            icon={<BloodDrop />}
                            loading={loading}
                            title="Blood Type"
                            description="Added blood type will be displayed here"
                            status={`${patient?.medicalRecord?.bloodType?.bloodType || patient?.medicalRecord?.bloodType?.genotype
                                ? 1
                                : 0
                                } type`}
                            onClick={() => setView('Blood Type')}
                        />
                        <StatusCard
                            containerVariant="col-span-6 lg:col-span-4 pb-4 lg:border-r border-neutral_stroke_2 px-4 border-b"
                            loading={loading}
                            variant="px-4"
                            icon={<Theraphy />}
                            title="Emergency Contact"
                            description="All added emergency contact(s) will be added here"
                            status={patient?.medicalRecord?.emergencyContact?.length}
                            onClick={() => setView('Emergency Contact')}
                        />
                        <StatusCard
                            variant="px-4"
                            containerVariant="col-span-6 lg:col-span-4 pl-4 pb-4 border-r lg:border-r-0 border-neutral_stroke_2 border-b"
                            loading={loading}
                            icon={<Procedure />}
                            title="Family Hospital"
                            description="Patient's family hospital will be displayed here"
                            status={patient?.medicalRecord?.familyHospital?.length}
                            onClick={() => setView('Family Hospital')}
                        />
                        <StatusCard
                            containerVariant="col-span-6 lg:col-span-4 pr-4 py-4 lg:border-r border-r-0 border-neutral_stroke_2 border-b"
                            loading={loading}
                            icon={<MedicalHistoryIcon />}
                            variant="px-4"
                            status={patient?.medicalRecord?.medicalDiagnosis?.length}
                            title="Medical History"
                            description="Patient's medical history will be displayed here"
                            onClick={() => setView('Medical History')}
                        />
                        <StatusCard
                            containerVariant="col-span-6 lg:col-span-4 px-4 py-4 border-r border-neutral_stroke_2 border-b"
                            variant="px-4"
                            loading={loading}
                            icon={<SurgeriesIcon />}
                            status={patient?.medicalRecord?.surgeries?.length}
                            title="Surgeries"
                            description="Patient's previous surgeries will be displayed here"
                            onClick={() => setView('Surgeries')}
                        />
                        <StatusCard
                            containerVariant="col-span-6 lg:col-span-4 pl-4 py-4 lg:border-r border-r-0 lg:border-r-0 border-neutral_stroke_2 border-b"
                            loading={loading}
                            icon={<AllergiesIcon />}
                            status={patient?.medicalRecord?.allergies?.length}
                            title="Allergies/Sensitivities"
                            description="All allergies will be displayed here"
                            onClick={() => setView('Allergies')}
                        />
                        <StatusCard
                            loading={loading}
                            variant="px-4"
                            containerVariant="col-span-6 pr-4 py-4 border-r border-neutral_stroke_2 border-b "
                            icon={<ImmunizationIcon />}
                            title="Immunization History"
                            description="All immunization History will be displayed here."
                            status={patient?.medicalRecord?.immunizationHistory?.length}
                            onClick={() => setView('Immunization History')}
                        />
                        <StatusCard
                            variant="px-4"
                            containerVariant="col-span-6 pl-4 py-4 border-r-0 border-neutral_stroke_2 border-b"
                            loading={loading}
                            icon={<Insurance />}
                            status={patient?.medicalRecord?.insuranceInformation?.length}
                            title="Insurance Information"
                            description="Add your insurance information to ensure hassle-free transactions and coverage."
                            onClick={() => setView('Insurance Information')}
                        />
                        <StatusCard
                            containerVariant="col-span-6 pr-4 py-4 border-r border-neutral_stroke_2"
                            variant="px-4"
                            loading={loading}
                            icon={<FamilyHistoryIcon />}
                            status={patient?.medicalRecord?.familyHistory?.length}
                            title="Family History"
                            description="Patient's family history will be displayed here"
                            onClick={() => setView('Family History')}
                        />
                        <StatusCard
                            containerVariant="col-span-6 pl-4 py-4 border-r-0 border-neutral_stroke_2"
                            variant="px-4"
                            icon={<Lifestyle />}
                            loading={loading}
                            title="Lifestyle Habits"
                            description="Patient's lifestyle habits will be displayed here"
                            status={totalAnswers}
                            onClick={() => setView('Lifestyle Habit')}
                        />
                    </div>
                )}
                {view && <View view={view} callback={() => setView('')} setModal={() => setFormType(view)} visitStatus={visitStatus} canUpdate={canUpdate} />}
                {formType && (
                    <Modal
                        closeModal={() => setFormType('')}
                        styles="min-w-[90%] md:min-w-[730px]"
                        title={`Add ${view}`}
                        subTitle="Lorem ipsum dolor sit amet consectetur. orbi id erat er"
                        containerVariant="bg-neutral_white"
                    >
                        <Form callback={handleCallback} />
                    </Modal>
                )}
            </SlideUp>
        </div>
    );
}

PatientHistory.propTypes = {
    loading: PropTypes.bool,
    className: PropTypes.string
};
