import React, { useEffect, useMemo, useState } from 'react';
import Navbar from 'components/Navbar';
import { SlideUp } from 'components/Animations';
import Table from 'components/Table';
import EmptyState from 'components/Table/EmptyState';
import useTable from 'hooks/useTable';
import { service_list_header } from 'mocks/clinicals/services';
import Card from './Components/Card';
import NewServiceCategory from './Components/Forms/NewServiceCategory';
import { useNavigate } from 'react-router-dom';
import Delete from 'components/Forms/Delete';
import { useDispatch } from 'react-redux';
import { setCategory, useServiceState } from 'store/modules/services';
import useService from 'hooks/fetch/useService';
import useDebounce from 'hooks/useDebounce';
import { useAuthState } from 'store/modules/auth';
import usePermission from 'hooks/usePermission';
import Restricted from 'HOC/Restricted';
import { service_category } from 'mocks/services';

export default function Services() {
  const [type, setType] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hasPermission } = usePermission()
  const [categoryDetails, setCategoryDetails] = useState({});
  const { user, institutionId, userType } = useAuthState();
  const { serviceCategories } = useServiceState();
  const { changePage, selectLimit, handleSearch, params } = useTable();
  const { get_service_categories, loading, delete_service_category } = useService();
  const { debouncedValue } = useDebounce(params.search);

  useEffect(() => {
    document.title = 'Service Mangement - Gabbi Health';
  }, []);
  const availableServiceCategory = useMemo(() => {
    return service_category.filter(item =>
      serviceCategories?.categories?.every(filterItem => filterItem.name !== item.name)
    );
  }, [serviceCategories?.categories])

  const getServiceCategories = () => {
    const userAccount = ['institution', 'staff'].includes(userType) ? 'institution' : 'provider'
    get_service_categories({
      ...params,
      type: userAccount,
      institutionId,
      providerId: userAccount === 'institution' ? '' : user?._id,
    });
  };

  useEffect(() => {
    getServiceCategories();
  }, [debouncedValue, params.page, params.limit]);

  const handleCloseModal = () => {
    setType('');
    setCategoryDetails({});
  };

  const handleCallback = () => {
    setType('');
    getServiceCategories();
  };
  const showEditModal = (item) => {
    setType('Edit');
    setCategoryDetails(item);
  };

  const handleSingleView = (item) => {
    dispatch(setCategory(item));
    navigate(`${item.name}`);
  };

  const showDeleteModal = (item) => {
    setType('Delete');
    setCategoryDetails(item);
  };

  const deleteCategory = () => {
    return hasPermission('Service Management', 'Delete') ?
      delete_service_category(categoryDetails?.name, handleCallback) : {}
  };

  const viewService = (item) => {
    return hasPermission('Service Management', 'View service category') ? handleSingleView(item) : {}
  }

  return (
    <>
      <Navbar title="Service Management" />
      <Restricted module='Service Management' permission='View service category'>
        <>
          <SlideUp className="h-[calc(100vh-300px)]">
            <div className="p-6 h-full">
              <Table
                title="Service List"
                tableContainer='w-full'
                headers={service_list_header}
                data={serviceCategories?.categories}
                hasHeader={false}
                totalCount={serviceCategories?.total}
                perPage={serviceCategories?.limit}
                currentPage={serviceCategories?.page}
                selectLimit={selectLimit}
                changePage={changePage}
                searchPlaceholder="Search by service name"
                loading={loading}
                handleSearch={handleSearch}
                emptyState={<EmptyState title="No Services Added" className="h-[calc(100vh-530px)] bg-neutral_white" />}
                buttonName={availableServiceCategory.length > 0 ? 'New Service Category' : ''}
                module="Service Management"
                permission="Add service category"
                buttonFnc={() => setType('New')}
              >
                <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  {serviceCategories?.categories?.map((item) => (
                    <Card
                      key={item._id}
                      data={item}
                      editFunc={() => showEditModal(item)}
                      delFunc={() => showDeleteModal(item)}
                      onClick={() => viewService(item)}
                    />
                  ))}
                </div>
              </Table>
            </div>
          </SlideUp>
          {type && (
            type === "Delete" ?
              <Delete
                title="Delete Service Category"
                description="Do you want to delete this service category?"
                cancel={() => setType('')}
                buttonName='Delete'
                loading={loading}
                deleteFnc={deleteCategory}
              /> :
              <NewServiceCategory
                type={type}
                title={`${type} Service Category`}
                closeModal={handleCloseModal}
                callback={getServiceCategories}
                data={categoryDetails}
              />
          )
          }
        </>
      </Restricted>
    </>
  );
}
