import React from 'react';
import { ReactComponent as Service } from 'assets/icons/service.svg';
import MoreOptions from 'components/Options/MoreOptions';
import { ReactComponent as Edit } from 'assets/svg/edit-icon.svg';
import { ReactComponent as Delete } from 'assets/icons/delete-icon.svg';
import { ReactComponent as Dot } from 'assets/icons/veritical-dot.svg';
import PropTypes from 'prop-types';
import Restricted from 'HOC/Restricted';
import usePermission from 'hooks/usePermission';

const Card = ({ data, editFunc, delFunc, onClick }) => {
  const { hasPermission } = usePermission()
  const options = [
    {
      id: 1,
      name: 'Edit category',
      value: 'edit',
      func: editFunc,
      permission: 'Update category',
      icon: <Edit className="shrink-0 cursor-pointer mr-2 h-[12.5px] w-[12.5px]" fill="#505959" />,
    },
    {
      id: 2,
      name: 'Delete category',
      value: 'delete',
      func: delFunc,
      permission: 'Delete category',
      icon: <Delete className="w-[12px] h-[13px] mr-2" />,
    },
  ];

  const showDropdown =
    hasPermission('Service Management', 'Update category') ||
    hasPermission('Service Management', 'Delete category');

  return (
    <div className="p-4 bg-[#F7F7F8] rounded-2xl" data-testid={data._id}>
      <div>
        <div className="flex justify-between">
          <Service fill="#36A1C5" />
          {showDropdown && (
            <MoreOptions position="right-[0px] top-[4px] w-[200px]" icon={Dot} className="relative">
              <>
                {options?.map((option) => (
                  <Restricted key={option?.id} module="Service Management" permission={option.permission}>
                    <button
                      key={option?.id}
                      type='button'
                      data-testid={option?.value}
                      className="w-full
                    cursor-pointer flex items-center first-of-type:mt-0 mt-2 py-[9.5px] hover:bg-[#dfe2e23b]
                    text-neutral_body hover:text-neutral_black hover:pl-[8px] rounded-[50px]"
                      onClick={option.func}
                    >
                      <p className="flex items-center">
                        {option.icon}
                        <span className="inline-block">{option?.name}</span>
                      </p>
                    </button>
                  </Restricted>
                ))}
              </>
            </MoreOptions>
          )}
        </div>
      </div>
      <div className="font-campton_r mt-4 cursor-pointer" data-testid={data?.name} onClick={onClick}>
        <p className="text-[#070808] text-14">{data?.name}</p>
        <p className="text-[#4F5559] text-12">{data?.description}</p>
      </div>
    </div>
  );
};

export default Card;
Card.propTypes = {
  data: PropTypes.object,
  editFunc: PropTypes.func,
  delFunc: PropTypes.func,
  onClick: PropTypes.func,
};
