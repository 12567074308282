import React, { useEffect, useState } from "react";
import { SlideUp } from "components/Animations";
import Card from "./Card";
import Pagination from "components/Pagination";
import TableFilter from "components/Table/TableFilter";
import RoleDetails from "./RoleDetails";
import Delete from "components/Forms/Delete";
import CreateAndEditRole from "./Forms/CreateAndEditRole";
import useRoles from "hooks/fetch/useRoles";
import { useSelector } from "react-redux";
import Spinner from "components/Spinner";
import useDebounce from "hooks/useDebounce";
import useTable from "hooks/useTable";
import Navbar from "components/Navbar";
import EmptyState from "components/Table/EmptyState";
import Restricted from "HOC/Restricted";

export default function RolesAndPermission() {
    const { loading, get_roles, delete_role } = useRoles()
    const { institutionId } = useSelector(state => state.auth)
    const { roles } = useSelector(state => state.roles)
    const [selectedRole, setSelectedRole] = useState()
    const [purpose, setPurpose] = useState('')
    const [itemToDelete, setItemToDelete] = useState('')
    const { params, changePage, handleSearch } = useTable(9)

    const { debouncedValue } = useDebounce(params.search)

    useEffect(() => {
        get_roles({ id: institutionId, params })
    }, [debouncedValue, params.page])

    const deleteSelectedRole = () => {
        delete_role(itemToDelete)
            .then((res) => {
                if (res?.code === 200) {
                    setItemToDelete('')
                    setSelectedRole()
                    get_roles({ id: institutionId, params })
                }
            })
    }

    return (
        <div>
            <Navbar title="Roles & Permissions" />
            <Restricted module="Roles and Permission" permission="View roles/permissions">
                <SlideUp>
                    <div className="flex flex-col justify-between p-[24px]">
                        <div className="min-h-[80vh] sm:min-h-0 sm:h-[72.5vh] overflow-y-auto">
                            <TableFilter
                                title="Roles"
                                buttonName="Create New Role"
                                handleSearch={handleSearch}
                                buttonFnc={() => setPurpose('create')}
                                module="Roles and Permission"
                                permission="Create new role"
                            />
                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[16px]">
                                {roles?.Roles?.map((role, i) =>
                                    <Card
                                        key={role?._id}
                                        id={role?._id}
                                        index={i}
                                        description={role?.description}
                                        role={role?.name}
                                        staffs={role.staffs}
                                        createdAt={role?.createdAt}
                                        onClick={() => setSelectedRole(role)}
                                    />
                                )}
                            </div>
                            {!roles?.Roles?.length &&
                                <div className="h-[80%]">
                                    {loading && !roles?.Roles?.length && <Spinner />}
                                    {!loading && !roles?.Roles?.length &&
                                        <EmptyState
                                            title='No Roles Added.'
                                        //caption='Lorem ipsum dolor sit amet consectetur. orbi id erat er'
                                        />
                                    }
                                </div>
                            }
                        </div>
                        <div className="flex justify-end">
                            {roles?.Roles?.length ?
                                <Pagination
                                    totalCount={roles?.count}
                                    perPage={Number(roles?.limit)}
                                    currentPage={Number(roles?.page) || 0}
                                    changePage={changePage}
                                /> : ''
                            }
                        </div>
                    </div >
                    {selectedRole &&
                        <RoleDetails
                            id={selectedRole?._id}
                            selectedRole={selectedRole}
                            editRole={() => setPurpose('edit')}
                            deleteRole={setItemToDelete}
                            closeModal={() => setSelectedRole()}
                        />
                    }
                    {purpose &&
                        <CreateAndEditRole
                            callback={() => {
                                setPurpose()
                                setSelectedRole()
                            }}
                            purpose={purpose}
                            role={selectedRole}
                            closeModal={() => setPurpose()}
                        />
                    }
                    {itemToDelete &&
                        <Delete
                            title="Delete Role?"
                            deleteFnc={deleteSelectedRole}
                            description='Would you like to delete this role? '
                            buttonName='Delete'
                            loading={loading}
                            cancel={() => setItemToDelete('')}
                        />
                    }
                </SlideUp >
            </Restricted>
        </div >
    )
}